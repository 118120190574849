import React, { useState, useEffect, useRef } from "react";
import { List, Button, Tag, Modal, Typography, Spin, Card } from "antd";
import { FaEraser } from "react-icons/fa";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { useSpring, animated } from "@react-spring/web";
import ChatInput from "./ChatInput";
import ChatFilters from "./ChatFilters"; // Ensure this component is defined
import ReactMarkdown from "react-markdown";
import LangflowClient from "../utils/LangflowClient";
import "./ChatComponent.css";

const { Title } = Typography;

const ChatComponent = ({ setOpen }) => {
  const [flow, setFlow] = useState(null);
  const [chatValue, setChatValue] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [lockChat, setLockChat] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const langflowClient = useRef();
  const messagesRef = useRef(null);
  const inputRef = useRef(null);
  const sessionId = useRef("");

  useEffect(() => {
    if (flow) {
      langflowClient.current = new LangflowClient(
        "https://langflow.antp.org.mx",
        flow.apiKey
      );
      initializeSession();
    }
  }, [flow]);

  const initializeSession = async () => {
    try {
      const response = await langflowClient.current.initiateSession(
        flow.id,
        null
      );
      sessionId.current = response;
      await fetchChatHistory();
    } catch (error) {
      console.error("Error initializing session:", error);
    }
  };

  const fetchChatHistory = async () => {
    try {
      const history = await langflowClient.current.fetchHistory(
        flow.id,
        sessionId.current
      );
      setChatHistory(
        history.map((msg) => ({
          message: msg.text,
          isSend: msg.sender === "User",
        }))
      );
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const sendMessage = () => {
    if (chatValue.trim() !== "") {
      setLockChat(true);
      const message = chatValue;
      setChatValue("");
      const filterString = formatFilters(filters);
      const fullMessage = `[FILTERS]${filterString}[/FILTERS] ${message}`;
      addChatHistory(fullMessage, true);
      sendAll({ message: fullMessage });
    } else {
      console.error("El mensaje no puede estar vacío.");
    }
  };

  const addChatHistory = (message, isSend) => {
    setChatHistory((old) => [...old, { message, isSend }]);
  };

  const clearChat = async () => {
    try {
      await langflowClient.current.deleteMessagesSession(sessionId.current);
      setChatHistory([]);
    } catch (error) {
      console.error("Error clearing chat history:", error);
    } finally {
      setLockChat(false);
    }
  };

  const sendAll = async (data) => {
    try {
      const response = await langflowClient.current.runFlow(
        flow.id,
        data.message,
        {}, // You can pass filters here if needed
        false,
        (data) => {
          console.log("Received:", data.chunk);
          updateLastMessage({ str: data.chunk });
        },
        (message) => {
          console.log("Stream Closed:", message);
        },
        (error) => {
          console.log("Stream Error:", error);
        }
      );
      if (response && response.outputs && response.outputs.length) {
        handleWsMessage(response);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLockChat(false);
    }
  };
  
  const updateLastMessage = ({ str }) => {
    setChatHistory((old) => {
      const newChat = [...old];
      if (str) {
        newChat[newChat.length - 1].message += str;
      }
      return newChat;
    });
  };
  
  const handleWsMessage = (data) => {
    console.log("Received message: ", JSON.stringify(data));
  
    let output = "";
    if (!data.message) {
      const flowOutputs = data.outputs[0];
      const firstComponentOutputs = flowOutputs.outputs[0];
      output = firstComponentOutputs.outputs.message.message;
    }
  
    setChatHistory((old) => [
      ...old,
      { message: data.message || output.text || "", isSend: false },
    ]);
  };
  

  const switchFlow = (name) => {
    setFlow(
      name === "smap2024"
        ? {
            name: "smap2024",
            id: "2335adb9-d9e1-4bdf-a7aa-5a3b0d35d89b",
            apiKey: "sk-u5W-aUvV18YVOx4y-ph_SDR_LLhBLtaqAL6rfNAdxys",
          }
        : {
            name: "smap2024-secretariado",
            id: "cb61c314-b9db-4a70-ae2a-4ed6efba228d",
            apiKey: "sk-u5W-aUvV18YVOx4y-ph_SDR_LLhBLtaqAL6rfNAdxys",
          }
    );
    setChatHistory([]);
  };

  const formatFilters = (filters) => {
    return `Tipo de Análisis: ${
      filters.analisis_tipo || "Probabilidad o General"
    }, Fecha: ${filters.date || "N/A"}, Hora: ${
      filters.time || "N/A"
    }, Mercancía: ${filters.mercancia || "N/A"}, Tipo de Vehículo: ${
      filters.tipoVehiculo || "N/A"
    }`;
  };

  const renderMessage = (chat) => {
    return chat?.message?.replace(/\[FILTERS\](.*?)\[\/FILTERS\]/, "").trim();
  };

  const renderMarkdownMessage = (chat) => {
    return <ReactMarkdown>{renderMessage(chat)}</ReactMarkdown>;
  };

  const animatedProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "95%",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      {!flow ? (
        <div style={{ marginBottom: "20px", display: "flex", gap: "10px" }}>
          <Card
            title="Datos de Laica"
            hoverable
            onClick={() => switchFlow("smap2024")}
            style={{ flex: 1 }}
          >
            Selecciona para interactuar con datos de Laica.
          </Card>
          <Card
            title="Datos del Secretariado"
            hoverable
            onClick={() => switchFlow("smap2024-secretariado")}
            style={{ flex: 1 }}
          >
            Selecciona para interactuar con datos del Secretariado.
          </Card>
        </div>
      ) : (
        <>
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <Title level={4}>
              Flujo seleccionado:{" "}
              <Tag color="blue">
                {flow.name === "smap2024"
                  ? "Datos de Laica"
                  : "Datos del Secretariado"}
              </Tag>
            </Title>
            <Button
              type="link"
              onClick={() => setFlow(null)}
              style={{ marginTop: "10px" }}
            >
              Cambiar Flujo
            </Button>
          </div>

          <div
            ref={messagesRef}
            style={{
              width: "100%",
              height: "500px",
              background: "#ffffff",
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              padding: "10px",
              overflowY: "auto",
            }}
          >
            {chatHistory.length > 0 ? (
              <List
                dataSource={chatHistory}
                renderItem={(chat, index) => (
                  <animated.div style={animatedProps}>
                    <List.Item
                      key={index}
                      className={
                        chat.isSend
                          ? "chat-bubble-sent"
                          : "chat-bubble-received"
                      }
                    >
                      <List.Item.Meta
                        title={
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            {chat.isSend ? "Tú" : "S-MAP Asistente"}
                          </span>
                        }
                        description={
                          <span style={{ color: "black" }}>
                            {renderMarkdownMessage(chat)}
                          </span>
                        }
                      />
                    </List.Item>
                  </animated.div>
                )}
              />
            ) : (
              <div style={{ textAlign: "center", color: "gray" }}>
                <p>👋 No hay mensajes en este flujo.</p>
              </div>
            )}
          </div>
          <div style={{ marginTop: "20px" }}>
            <div style={{ marginBottom: "20px" }}>
              <Button
                type="primary"
                onClick={() => setFilterModalVisible(true)}
              >
                Configurar Filtros
              </Button>
            </div>
            <Modal
              title="Filtros"
              visible={filterModalVisible}
              onCancel={() => setFilterModalVisible(false)}
              footer={null}
            >
              <ChatFilters setFilters={setFilters} />
            </Modal>
            <div style={{ marginBottom: "10px" }}>
              {Object.keys(filters).map((key) => (
                <Tag key={key} color="blue">
                  {key}: {filters[key]}
                </Tag>
              ))}
            </div>
            <ChatInput
              chatValue={chatValue}
              lockChat={lockChat}
              sendMessage={sendMessage}
              setChatValue={setChatValue}
              inputRef={inputRef}
              disabled={lockChat}
            />
          </div>
          {lockChat && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Spin size="large" />
              <span style={{ marginLeft: "10px", color: "gray" }}>
                Pensando...
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatComponent;
